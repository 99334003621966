$theme-colors: (
    "info": tomato,
    "danger": teal
);

@import "~bootstrap/scss/bootstrap";
/* The following line can be included in your src/index.js or App.js file */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  text-align: center;
  width:100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//a {
//  display: inline-block;
//  background-color: #ecf6fe;
//  padding: 10px 20px;
//  font-family: sans-serif, Arial;
//  font-size: 16px;
//  border: 2px solid #cad5ff;
//  border-radius: 4px;
//  width: 100%;
//}

#pivotTable {
  background: white;
  overflow: scroll;
}

.pivotTable {
  background: white;
  overflow: scroll;
}

// popover
.glossaryTerms{
  color: var(--popover-term-color);
  text-decoration: var(--popover-term-decoration);
  font-style: var(--popover-term-style);
}

.spinner-container{
  height: 100%;
  display: grid;
  place-content: center;
}

.App{
  height: 100vh;
}

// NavBar
.navbar {
  position: fixed;
  top: 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  z-index: 1;
}

.navbar-header {
  text-align: center;
  width: 100%;
}

.navbar-collapse {
  justify-content: end;
}

.navbar-nav {
  max-height: 100px;
}

@media screen and (max-width: 992px) {
  .navbar-header {
      width: 60%;
      font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
  .navbar-header {
      width: 50%;
  }
}

.page-content {
  margin-top: 100px;
}